import { ref, Ref } from 'vue';
import { IFeaturePlanHandleDialog } from '@/components/view/super/feature-plan-handle-dialog';

const initFormData: IFeaturePlanHandleDialog.FeaturePlanForm = {
    Items: [],
    Name: '',
    FeaturePlanType: '',
    MonitorType: '0'
};
const initHandleDialog = () => {
    const isShowFeaturePlanHandleDialog = ref(false);

    const operationType = ref<'add'|'edit'>('add');

    const initForm = ref<IFeaturePlanHandleDialog.FeaturePlanForm>(initFormData);

    const add = () => {
        operationType.value = 'add';
        initForm.value = initFormData;
        isShowFeaturePlanHandleDialog.value = true;
    };

    const edit = (listItem: IFeaturePlanHandleDialog.FeaturePlanForm) => {
        operationType.value = 'edit';
        initForm.value = listItem;
        isShowFeaturePlanHandleDialog.value = true;
    };

    return {
        isShowFeaturePlanHandleDialog,
        operationType,
        initForm,
        add,
        edit
    };
};

const initInfoDialog = (detail: Ref<IFeaturePlanHandleDialog.FeaturePlanForm>) => {
    const isShowFeaturePlanInfoDialog = ref(false);
    const initForm = detail;
    const info = (listItem: IFeaturePlanHandleDialog.FeaturePlanForm) => {
        initForm.value = listItem;
        isShowFeaturePlanInfoDialog.value = true;
    };
    return {
        isShowFeaturePlanInfoDialog,
        info
    };
};
export default null;
export {
    initHandleDialog,
    initInfoDialog
};