import { ref, Ref } from 'vue';
import { getSearch, RequestData } from '@/components/common/list';

const initListData = () => {
    const updateToList = ref(0);

    const listRequestData: Ref<RequestData> = ref({
        url: 'v3/web/community/featurePlan/getFeaturePlanPage',
        param: getSearch({
            Type: 'all',
            searchKey: 'Name',
            searchValue: ''
        })
    });

    const saveParamInPath = ref(false);
    const searchList = () => {
        updateToList.value += 1;
        saveParamInPath.value = true;
    };

    return {
        updateToList,
        listRequestData,
        searchList,
        saveParamInPath
    };
};

export default initListData;