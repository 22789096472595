
import { defineComponent } from 'vue';
import addButton from '@/components/common/add-button/index.vue';
import {
    listPagination
} from '@/components/common/list';
import featurePlanHandleDialog from '@/components/view/super/feature-plan-handle-dialog';
import featurePlanInfoDialog from '@/components/view/super/feature-plan-info-dialog';
import { initHandleDialog, initInfoDialog } from '@/views/super/feature-plan/initDialog';
import initConstData from './initConstData';
import initListData from './initListData';

export default defineComponent({
    components: {
        addButton,
        listPagination,
        featurePlanHandleDialog,
        featurePlanInfoDialog
    },
    setup() {
        // 初始化列表表头,搜索条件下拉参数
        const {
            featurePlanTypeOption, searchKeyList, headers, featurePlanTypeTurn,
            featureItemsTurn
        } = initConstData();

        // 初始化list组件的参数
        const {
            updateToList, listRequestData, saveParamInPath, searchList
        } = initListData();

        // handleDialog操作
        const {
            isShowFeaturePlanHandleDialog, operationType, initForm, add, edit
        } = initHandleDialog();

        const { info, isShowFeaturePlanInfoDialog } = initInfoDialog(initForm);

        return {
            updateToList,
            listRequestData,
            featurePlanTypeOption,
            searchKeyList,
            headers,
            saveParamInPath,
            searchList,
            isShowFeaturePlanHandleDialog,
            operationType,
            initForm,
            add,
            edit,
            featurePlanTypeTurn,
            featureItemsTurn,
            info,
            isShowFeaturePlanInfoDialog
        };
    }
});
